import React from "react";
import { Jumbotron } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { Helmet } from "react-helmet";
import colorCodeJsonData from "../data/color_code.json";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import {
  FacebookShareButton,
  RedditShareButton,
  PinterestShareButton,
  TwitterShareButton,
  FacebookIcon,
  RedditIcon,
  PinterestIcon,
  TwitterIcon,
} from "react-share";

const { SearchBar } = Search;
const products = colorCodeJsonData.codes.sort((a, b) =>
  a.name > b.name ? 1 : -1
);
const columns = [
  {
    dataField: "swatch",
    text: "Color Swatch",
    formatter: imgFormatter,
  },
  {
    dataField: "name",
    text: "Color Name",
  },
  {
    dataField: "code",
    text: "Color Code",
  },
];
const shareURL = "https://forulu.com/lululemon-color-code-finder";

function imgFormatter(cell, row) {
  return (
    <img
      style={{ borderRadius: "50%" }}
      src={"img/color_code/" + cell}
      alt={"lululemon_color_" + cell}
      width="72px"
      height="72px"
    ></img>
  );
}

// Implement startWith instead of contain
function customMatchFunc({ searchText, value, column, row }) {
  if (typeof value !== "undefined") {
    return value.startsWith(searchText);
  }
  return false;
}
function ColorCodeSearch() {
  return (
    <div id="color-code-search">
      <Helmet>
        <meta
          property="og:title"
          content="Forulu Asia Exclusives - Lululemon Color Code Finder"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://forulu.com/img/forulu-fb-color-code-finder.png"
        />
        <meta
          property="og:description"
          content="A color code finder to help you figure out the color of your item"
        />
      </Helmet>
      <div className="container">
        <div className="row pt-3 pb-2">
          <div className="col-12">
            <div className="section-title">
              <h2>Lululemon Color Code Finder</h2>
            </div>
            <p className="lead mb-0">
              This color code finder helps you figure out the color of your
              item. No more guesswork!
            </p>
            <p className="lead mb-2">
              Know a color code not in the list? Please help to contribute to
              the tool{" "}
              <a href="https://docs.google.com/spreadsheets/d/1KyYcfexAF5OTKSi_bEXBwNRl3Nbx_lcxRCgN3g12vfU/edit?usp=sharing">
                here.
              </a>
            </p>
            <p className="lead mb-2">
              Share this tool:
              <FacebookShareButton url={shareURL} className="ml-2 mb-2">
                <FacebookIcon size={32} round />
              </FacebookShareButton>
              <TwitterShareButton
                url={shareURL}
                title="Lululemon Color Code Finder - Forulu Asia Exclusives"
                className="mb-2 mx-1"
              >
                <TwitterIcon size={32} round />
              </TwitterShareButton>
              <PinterestShareButton
                media="https://forulu.com/img/lululemon-color-code-finder-pinterest.png"
                url={shareURL}
                className="mb-2"
              >
                <PinterestIcon size={32} round />
              </PinterestShareButton>
              <RedditShareButton url={shareURL} className="mb-2 mx-1">
                <RedditIcon size={32} round />
              </RedditShareButton>
            </p>
            <div>
              <ToolkitProvider
                keyField="id"
                data={products}
                columns={columns}
                search={{ customMatchFunc }}
              >
                {(props) => (
                  <div>
                    <div className="float-right col-12 col-md-4 px-0">
                      <SearchBar {...props.searchProps} />
                    </div>
                    <BootstrapTable
                      {...props.baseProps}
                      bordered={false}
                      hover={true}
                      bootstrap4={true}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </div>
          </div>
        </div>
      </div>
      <Jumbotron className="beige">
        <h4 className="text-center">
          Want Asia exclusives but live in North America or Europe?
        </h4>
        <hr className="jumbotron-divider col-10 col-lg-5 mt-2 mb-4"></hr>
        <div className="d-flex justify-content-center">
          <a
            href="/#how-it-works"
            role="button"
            className="btn btn-primary btn-xl px-4 py-3"
          >
            Yes, I want them now!
          </a>
        </div>
      </Jumbotron>
    </div>
  );
}

export default ColorCodeSearch;
