import React from "react";
import { Button } from "react-bootstrap";

function Navigation() {
  return (
    <nav className="navbar navbar-expand-lg navbar-light">
      <div className="container">
        {/* Logo and Name */}
        <a className="navbar-brand page-scroll" href="/#page-top">
          <img
            src="img/forulu_logo.png"
            className="img-fluid"
            alt="Forulu Logo"
          />
          Forulu
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        {/* Menu items */}
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="nav navbar-nav mx-auto mt-2 no-bullet">
            <li className="nav-item mr-lg-2">
              <a href="/#how-it-works" className="nav-link page-scroll">
                HOW IT WORKS
              </a>
            </li>
            <li className="nav-item mx-lg-2">
              <a href="/#pricing" className="nav-link page-scroll">
                PRICING
              </a>
            </li>
            <li className="nav-item mx-lg-2">
              <a href="/#testimonials" className="nav-link page-scroll">
                KIND WORDS
              </a>
            </li>
            <li className="nav-item mx-lg-2">
              <a href="/#contact" className="nav-link page-scroll">
                CONTACT
              </a>
            </li>
          </ul>
          <div className="dropdown-divider"></div>
          {/*WIP add back when done with articles*/}
          <ul className="nav navbar-nav mx-auto mt-2 no-bullet">
            {/* <ul className="nav navbar-nav mx-auto pl-lg-10 mt-2 mt-lg-0"> */}
            <li className="nav-item mx-lg-2">
              <a
                href="/lululemon-color-code-finder"
                className="nav-link page-scroll"
              >
                COLOR CODE FINDER
              </a>
            </li>
            <div className="dropdown navbar-nav px-0">
              <Button
                variant="link"
                className="nav-link dropdown-toggle btn-noShadow col-2 border-0"
                id="dropdownMenu2"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                ARTICLES
              </Button>
              <div
                className="dropdown-menu border-right-0 border-left-0 border-bottom-0"
                aria-labelledby="dropdownMenu2"
              >
                <a
                  className="dropdown-item"
                  href="/9-ways-to-spot-fake-lululemon-products"
                >
                  9 ways to spot fake Lululemon Products
                </a>
              </div>
            </div>
            {/* <li className="nav-item mx-lg-3">
              <a href="/#pricing" className="nav-link page-scroll">
                ARTICLES
              </a>
            </li> */}
          </ul>

          <div className="mt-lg-0 mt-2">
            <a
              className="btn btn-primary page-scroll px-4"
              href="/#purchase"
              role="button"
            >
              Order Now
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navigation;
