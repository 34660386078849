import React from "react";
import { Card } from "react-bootstrap";

function HowItWorks() {
  return (
    <div id="how-it-works">
      <div className="container">
        <div className="row pt-3 pt-md-5">
          <div className="col-12 col-md-5 mr-md-5 section-title">
            <h2 className="mb-3 mb-md-4">How it works</h2>
          </div>
          <div className="col-12 col-lg-10 mb-5 mx-auto">
            {/* Mobile view */}
            <Card className="d-md-none">
              <Card.Body>
                <div className="row py-4">
                  <div className="col-3 align-self-center">
                    <div className="circle-style-mobile d-flex align-items-center justify-content-center">
                      <div className="h3 mb-0 font-weight-bolder">1</div>
                    </div>
                  </div>
                  <div className="p pl-0 col-9">
                    Found something you like from Asia? Let us know by filling
                    up the <a href="/#purchase">form</a> below with your order
                  </div>
                </div>
                <div className="row py-4">
                  <div className="col-3 align-self-center">
                    <div className="circle-style-mobile d-flex align-items-center justify-content-center">
                      <div className="h3 mb-0 font-weight-bolder">2</div>
                    </div>
                  </div>
                  <div className="p pl-0 col-9 d-flex align-items-center">
                    We will send you an email within 2 working days to confirm
                    your purchase and total price
                  </div>
                </div>
                <div className="row py-4">
                  <div className="col-3 align-self-center">
                    <div className="circle-style-mobile d-flex align-items-center justify-content-center">
                      <div className="h3 mb-0 font-weight-bolder">3</div>
                    </div>
                  </div>
                  <div className="p pl-0 col-9 d-flex align-items-center">
                    Confirm your order by responding to our email and we will
                    help you secure your purchase
                  </div>
                </div>
                <div className="row py-4">
                  <div className="col-3 align-self-center">
                    <div className="circle-style-mobile d-flex align-items-center justify-content-center">
                      <div className="h3 mb-0 font-weight-bolder">4</div>
                    </div>
                  </div>
                  <div className="p pl-0 col-9 d-flex align-items-center">
                    We will inform you the purchase is made and when Lululemon
                    ships it to our address
                  </div>
                </div>
                <div className="row py-4">
                  <div className="col-3 align-self-center">
                    <div className="circle-style-mobile d-flex align-items-center justify-content-center">
                      <div className="h3 mb-0 font-weight-bolder">5</div>
                    </div>
                  </div>
                  <div className="p pl-0 col-9 d-flex align-items-center">
                    Please make payment within 2 days of receiving the invoice
                  </div>
                </div>
                <div className="row py-4">
                  <div className="col-3 align-self-center">
                    <div className="circle-style-mobile d-flex align-items-center justify-content-center">
                      <div className="h3 mb-0 font-weight-bolder">6</div>
                    </div>
                  </div>
                  <div className="p pl-0 col-9 d-flex align-items-center">
                    We will inform you of the expected date of arrival
                  </div>
                </div>
              </Card.Body>
            </Card>

            {/* Ipad/Desktop view */}
            <Card className="d-none d-md-block">
              <Card.Body>
                <div className="row py-4">
                  <div className="col-2 d-block">
                    <div className="circle-style d-flex align-items-center justify-content-center mx-auto">
                      <div className="h3 mb-0 font-weight-bolder">1</div>
                    </div>
                  </div>
                  <div className="lead font-weight-normal col-8">
                    Found something you like from Asia? Let us know by filling
                    up the <a href="/#purchase">form</a> below with your order
                  </div>
                  <img
                    className="how-it-works-icon d-flex mx-auto"
                    src="img/step_1_icon.png"
                    alt="step_1_icon"
                  />
                </div>
                <div className="row py-4">
                  <div className="col-2 d-block">
                    <div className="circle-style d-flex align-items-center justify-content-center mx-auto">
                      <div className="h3 mb-0 font-weight-bolder">2</div>
                    </div>
                  </div>
                  <div className="lead font-weight-normal col-8 d-flex align-items-center">
                    We will send you an email within 2 working days to confirm
                    your purchase and total price
                  </div>
                  <img
                    className="how-it-works-icon d-flex mx-auto"
                    src="img/step_2_icon.png"
                    alt="step_2_icon"
                  />
                </div>
                <div className="row py-4">
                  <div className="col-2 d-block">
                    <div className="circle-style d-flex align-items-center justify-content-center mx-auto">
                      <div className="h3 mb-0 font-weight-bolder">3</div>
                    </div>
                  </div>
                  <div className="lead font-weight-normal col-8 d-flex align-items-center">
                    Confirm your order by responding to our email and we will
                    help you secure your purchase
                  </div>
                  <img
                    className="how-it-works-icon d-flex mx-auto"
                    src="img/step_3_icon.png"
                    alt="step_3_icon"
                  />
                </div>
                <div className="row py-4">
                  <div className="col-2">
                    <div className="circle-style d-flex align-items-center justify-content-center mx-auto">
                      <div className="h3 mb-0 font-weight-bolder">4</div>
                    </div>
                  </div>
                  <div className="lead font-weight-normal col-8 d-flex align-items-center">
                    We will inform you the purchase is made and when Lululemon
                    ships it to our address
                  </div>
                  <img
                    className="how-it-works-icon d-flex mx-auto"
                    src="img/step_4_icon.png"
                    alt="step_4_icon"
                  />
                </div>
                <div className="row py-4">
                  <div className="col-2">
                    <div className="circle-style d-flex align-items-center justify-content-center mx-auto">
                      <div className="h3 mb-0 font-weight-bolder">5</div>
                    </div>
                  </div>
                  <div className="lead font-weight-normal col-8 d-flex align-items-center">
                    Please make payment within 2 days of receiving the invoice
                  </div>
                  <img
                    className="how-it-works-icon d-flex mx-auto"
                    src="img/step_5_icon.png"
                    alt="step_5_icon"
                  />
                </div>
                <div className="row py-4">
                  <div className="col-2">
                    <div className="circle-style d-flex align-items-center justify-content-center mx-auto">
                      <div className="h3 mb-0 font-weight-bolder">6</div>
                    </div>
                  </div>
                  <div className="lead font-weight-normal col-8 d-flex align-items-center">
                    We will inform you of the expected date of arrival
                  </div>
                  <img
                    className="how-it-works-icon d-flex mx-auto"
                    src="img/step_6_icon.png"
                    alt="step_6_icon"
                  />
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HowItWorks;
