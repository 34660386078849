import React, { Component } from "react";

export class Contact extends Component {
  render() {
    return (
      <div>
        <div id="contact">
          <div className="container">
            <div className="row py-5 py-md-5 text-center">
              <div className="col-12">
                <div className="section-title">
                  <h2>Got more questions?</h2>
                </div>
                <div className="col-12 col-md-7 mx-auto">
                  <p className='lead mb-4'>
                    If you have any questions about an existing order, or curious about anything else, please reach out to us, we are happy to help.
                  </p>
                </div>
              </div>
              <div className="col-12">
                <a href="mailto:&#x68;ello%40for&#x75;lu.com" role="button" className="btn btn-primary btn-xl px-6 py-3">
                  Contact Us
                  </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Contact;
