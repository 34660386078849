import React, { Component } from "react";
import Navigation from "./components/navigation";
import Header from "./components/header";
import HowItWorks from "./components/howitworks";
import Pricing from "./components/pricing";
import Testimonials from "./components/testimonials";
import Purchase from "./components/purchase";
import Footer from "./components/footer";
import Contact from "./components/contact";
import FakeArticle from "./components/fakeArticle";
import ColorCodeSearch from "./components/colorCodeSearch";
import landingPageJsonData from "./data/data.json";
import { Switch, Route } from "react-router-dom";
import { initGA } from "./utils/Tracking";

export class App extends Component {
  state = {
    landingPageData: {},
  };
  getlandingPageData() {
    this.setState({ landingPageData: landingPageJsonData });
  }

  componentDidMount() {
    this.getlandingPageData();
    initGA("G-WFZ9P9XT4E");
  }

  render() {
    return (
      <div>
        <Navigation />
        <Switch>
          <Route path="/9-ways-to-spot-fake-lululemon-products">
            <FakeArticle />
          </Route>
          <Route path="/lululemon-color-code-finder">
            <ColorCodeSearch />
          </Route>
          <Route path="/">
            <Header data={this.state.landingPageData.Header} />
            <HowItWorks />
            <Pricing />
            <Testimonials data={this.state.landingPageData.Testimonials} />
            <Purchase />
            <Contact />
          </Route>
        </Switch>
        <Footer data={this.state.landingPageData.Footer} />
      </div>
    );
  }
}

export default App;
