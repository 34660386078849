import React from "react";
import { Card } from "react-bootstrap";

function Pricing() {
  return (
    <div id="pricing">
      <div className="container">
        <div className="row pt-3 pt-md-5">
          <div className="col-12 col-md-5 mr-md-5 section-title">
            <h2 className="mb-3 mb-md-4">Pricing</h2>
          </div>
        </div>
        <div className="row justify-content-center">
          {/* Displays on medium and bigger only */}
          <div className="col-12 col-lg-4 mb-5">
            <Card>
              <Card.Body>
                <div className="h2 font-weight-bold">What you pay</div>
                <div className="row py-3 py-lg-4">
                  <div className="col-3 d-block">
                    <div className="circle-style d-flex align-items-center justify-content-center mx-auto">
                      <div className="h3 mb-0 font-weight-bolder">1</div>
                    </div>
                  </div>
                  <div className="lead font-weight-normal col-9 d-flex align-items-center">
                    Product cost
                  </div>
                </div>
                <div className="row py-3 py-lg-4">
                  <div className="col-3 d-block">
                    <div className="circle-style d-flex align-items-center justify-content-center mx-auto">
                      <div className="h3 mb-0 font-weight-bolder">2</div>
                    </div>
                  </div>
                  <div className="lead font-weight-normal col-9 d-flex align-items-center">
                  15% gratuity
                  </div>
                </div>
                <div className="row py-3 py-lg-4">
                  <div className="col-3 d-block">
                    <div className="circle-style d-flex align-items-center justify-content-center mx-auto">
                      <div className="h3 mb-0 font-weight-bolder">3</div>
                    </div>
                  </div>
                  <div className="lead font-weight-normal col-9 d-flex align-items-center">
                    <div className="row pl-3">
                      Delivery by actual weight
                      <ul className="pl-3 col-8">
                        <li>1st 500g:</li>
                        <li>per 500g after:</li>
                      </ul>
                      <ul className="pl-0 col-4 no-bullet">
                        <li>SGD 35</li>
                        <li>SGD 10</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>

          <div className="col-12 col-lg-6 mb-5">
            <Card className="h-100">
              <Card.Body>
                <div className="h2 font-weight-bold">Example</div>
                <div className="row">
                  <div className="h3 mb-0 mx-auto">$140.80 SGD</div>
                </div>
                <div className="row">
                  <p className="lead-2 mx-auto font-weight-normal">
                    (TOTAL PRICE OF AN ALIGN TANK FROM FORULU)
                  </p>
                </div>
                <div className="row">
                  <div className="col-5 d-flex align-items-center">
                    <img
                      src="img/align-tank-iron-blue-IRBU.png"
                      alt="align-tank-pricing-example"
                      className="img-fluid"
                    />
                  </div>
                  <div className="col-7">
                    <div className="row pb-3 pb-lg-4">
                      <div className="col-3 d-block">
                        <div className="circle-style-mobile d-flex align-items-center justify-content-center mx-auto">
                          <div className="h3 mb-0 font-weight-bolder">1</div>
                        </div>
                      </div>

                      <div className="col-9 pl-4 pl-sm-0 d-block">
                        <div className="row">
                          <div className="col-12 lead font-weight-normal">
                            $92 SGD
                          </div>
                        </div>
                        <div className="row">
                          <p className="col-12 lead-2 mx-auto font-weight-normal">
                            based on official Lululemon stores in Singapore
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row py-3 py-lg-4">
                      <div className="col-3 d-block">
                        <div className="circle-style-mobile d-flex align-items-center justify-content-center mx-auto">
                          <div className="h3 mb-0 font-weight-bolder">2</div>
                        </div>
                      </div>
                      <div className="col-9 pl-4 pl-sm-0 d-block">
                        <div className="row">
                          <div className="lead font-weight-normal col-12">
                            $13.80 SGD
                          </div>
                        </div>
                        <div className="row">
                          <p className="col-12 lead-2 mx-auto font-weight-normal">
                            15% gratuity
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row py-3 py-lg-4">
                      <div className="col-3 d-block">
                        <div className="circle-style-mobile d-flex align-items-center justify-content-center mx-auto">
                          <div className="h3 mb-0 font-weight-bolder">3</div>
                        </div>
                      </div>
                      <div className="col-9 pl-4 pl-sm-0 d-block">
                        <div className="row">
                          <div className="lead font-weight-normal col-12">
                            <div className="row pl-3">$35 SGD</div>
                          </div>
                        </div>
                        <div className="row">
                          <p className="col-12 lead-2 mx-auto font-weight-normal">
                            {"delivery for <500g"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Pricing;
