import React, { Component } from "react";

export class Header extends Component {
  render() {
    return (
      <header id="header">
        <div className="intro">
          <div className="overlay">
            <div className="container">
              <div className="row justify-content-around justify-content-sm-start align-items-sm-center min-vh-100">
                {/* Key words */}
                <div className="d-none d-sm-block col-12 col-sm-7 intro-text mb-sm-5 order-sm-2">
                  <h1>{this.props.data ? this.props.data.title : "Loading"}</h1>
                  <p className="lead pt-3 mb-0">
                    {this.props.data ? this.props.data.subtitle_1 : "Loading"}
                  </p>
                  <p className="lead pb-3 mb-3">
                    {this.props.data ? this.props.data.subtitle_2 : "Loading"}
                  </p>
                  <a
                    href="#how-it-works"
                    className="btn btn-primary btn-xl page-scroll px-5 py-3"
                  >
                    Get Started
                  </a>{" "}
                </div>
                {/* Top split, shown only in mobile */}
                <div className="d-block d-sm-none col-12 intro-text order-sm-2">
                  <h1>{this.props.data ? this.props.data.title : "Loading"}</h1>
                </div>
                {/* Image of item */}
                <div className="col-12 col-sm-5 mb-sm-5 order-sm-1">
                  <img
                    src={this.props.data ? this.props.data.img : "Loading"}
                    alt="..."
                    className="img-fluid img-header"
                  />
                </div>
                {/* Bottom split, shown only in mobile */}
                <div className="d-block d-sm-none col-12 intro-text-subtitle text-center my-2 order-sm-3">
                  {/* <p className='lead pt-3 mb-0'>
                    {this.props.data ? this.props.data.subtitle_1 : "Loading"}
                  </p>
                  <p className='lead pb-3 mb-3'>
                    {this.props.data ? this.props.data.subtitle_2 : "Loading"}
                  </p> */}
                  <a
                    href="#how-it-works"
                    className="btn btn-primary page-scroll px-4 py-2"
                  >
                    Get Started
                  </a>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
