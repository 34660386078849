import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Modal, Button } from "react-bootstrap";

function Purchase() {
  const { register, handleSubmit, errors } = useForm();

  const encode = (data) => {
    const formData = new FormData();
    const items = {};
    Object.keys(data).forEach((k) => {
      if (k.includes("Item")) {
        items[k] = data[k];
      } else {
        formData.append(k, data[k]);
      }
    });
    console.log(items);
    formData.append("items", JSON.stringify(items));
    return formData;
  };

  //Handles Submission modal state
  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const handleClose = () => {
    setShowSubmitModal(false);
    window.scrollTo(0, 0);
    window.location.reload();
  };
  const handleShow = () => setShowSubmitModal(true);

  const onSubmit = (e) => {
    console.log(e);
    handleShow();
    const data = Object.assign({}, { "form-name": "purchase-details" }, e);
    console.log(data);
    fetch("/", {
      method: "POST",
      // headers: { "Content-Type": 'multipart/form-data; boundary=random' },
      body: encode(data),
    });
    // e.preventDefault();
  };
  const [indexes, setIndexes] = useState([]);
  const [counter, setCounter] = useState(0);
  const addProduct = () => {
    setIndexes((prevIndexes) => [...prevIndexes, counter]);
    setCounter((prevCounter) => prevCounter + 1);
  };

  const removeProduct = (index) => () => {
    setIndexes((prevIndexes) => [
      ...prevIndexes.filter((item) => item !== index),
    ]);
    setCounter((prevCounter) => prevCounter);
  };
  // console.log(errors);
  return (
    <div>
      <div id="purchase">
        <div className="container">
          <div className="row py-3 py-md-3">
            <div className="col-12">
              <div className="section-title">
                <h2>Place an order</h2>
              </div>
            </div>
            <div className="col-12 col-md-12">
              <form name="purchase-details" onSubmit={handleSubmit(onSubmit)}>
                <div className="card card-purchase-box">
                  <div className="card-header ml-0" id="itemList">
                    <h5 className="mb-0">
                      <button
                        className="btn btn-purchase-header"
                        data-toggle="collapse"
                        data-target="#collapseItems-off"
                        aria-expanded="true"
                        aria-controls="collapseItems"
                      >
                        Shopping Basket
                      </button>
                    </h5>
                  </div>
                  <div
                    id="collapseItems"
                    className="collapse show"
                    aria-labelledby="itemList"
                  >
                    <div className="card-body pt-0">
                      {indexes.map((index) => {
                        const fieldName = `Item ${index}`;
                        return (
                          <div className="card purchase-card px-3 py-3 my-2">
                            <div className="form-inline d-flex justify-content-between">
                              <label
                                className="subtitle-1"
                                htmlFor={`${fieldName}`}
                              >
                                {/* {`Item ${index + 1}`} */}
                              </label>
                              <button
                                type="button"
                                className="btn item-remove"
                                onClick={removeProduct(index)}
                              >
                                Remove{" "}
                              </button>
                            </div>
                            <div
                              className="form-group"
                              key={`${fieldName}_ProductURL`}
                            >
                              <label htmlFor={`${fieldName}_ProductURL`}>
                                lululemon Product URL
                              </label>
                              <input
                                type="url"
                                className="form-control"
                                id={`${fieldName}_ProductURL`}
                                placeholder="E.g. https://www.lululemon.com.hk/en-hk/p/align-super-high-rise-pant-26%22-asia-fit/LW5CYWA.html"
                                name={`${fieldName}_ProductURL`}
                                ref={register({ required: true })}
                              />

                              <div className="form-error">
                                {errors[fieldName + "_ProductURL"] &&
                                  "Please enter a lululemon product URL"}
                              </div>
                            </div>
                            <div
                              className="form-group"
                              key={`${fieldName}_Color`}
                            >
                              <label htmlFor={`${fieldName}_Color`}>
                                Color
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id={`${fieldName}_Color`}
                                placeholder=""
                                name={`${fieldName}_Color`}
                                ref={register({
                                  required: true,
                                  message:
                                    "Please enter the product color you want",
                                })}
                              />
                              <div className="form-error">
                                {errors[fieldName + "_Color"] &&
                                  "Please enter a product color"}
                              </div>
                            </div>
                            <div
                              className="form-group"
                              key={`${fieldName}_Quantity`}
                            >
                              <label htmlFor={`${fieldName}_Quantity`}>
                                Quantity
                              </label>
                              <select
                                className="form-control"
                                id={`${fieldName}_Quantity`}
                                name={`${fieldName}_Quantity`}
                                ref={register({ required: true })}
                              >
                                <option selected disabled value="">
                                  Select no.
                                </option>
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                                <option>6</option>
                                <option>7</option>
                                <option>8</option>
                                <option>9</option>
                                <option>10</option>
                                <option>11</option>
                                <option>12</option>
                                <option>13</option>
                                <option>14</option>
                                <option>15</option>
                              </select>
                              <div className="form-error">
                                {errors[fieldName + "_Quantity"] &&
                                  "Please select a valid quantity to purchase"}
                              </div>
                            </div>
                            <div
                              className="form-group"
                              key={`${fieldName}_Size`}
                            >
                              <label htmlFor={`${fieldName}_Size`}>Size</label>
                              <select
                                className="form-control"
                                id={`${fieldName}_Size`}
                                name={`${fieldName}_Size`}
                                ref={register({ required: true })}
                              >
                                <option selected disabled value="">
                                  Select size
                                </option>
                                <option>XXS</option>
                                <option>XS</option>
                                <option>S</option>
                                <option>M</option>
                                <option>L</option>
                                <option>XL</option>
                                <option>XXL</option>
                                <option>3XL</option>
                                <option>4XL</option>
                                <option>5XL</option>
                                <option>0</option>
                                <option>2</option>
                                <option>4</option>
                                <option>6</option>
                                <option>8</option>
                                <option>10</option>
                                <option>12</option>
                                <option>14</option>
                                <option>16</option>
                                <option>18</option>
                                <option>20</option>
                                <option>22</option>
                                <option>24</option>
                                <option>
                                  Other sizes - Indicate in remarks below
                                </option>
                              </select>
                              <div className="form-error">
                                {errors[fieldName + "_Size"] &&
                                  "Please select a valid size"}
                              </div>
                            </div>
                            <div
                              className="form-group"
                              key={`${fieldName}_Remarks`}
                            >
                              <label htmlFor={`${fieldName}_Remarks`}>
                                Remarks
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id={`${fieldName}_Remarks`}
                                placeholder="E.g. If out of stock, get size L instead"
                                name={`${fieldName}_Remarks`}
                                ref={register}
                              />
                            </div>
                          </div>
                        );
                      })}
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={addProduct}
                      >
                        + Add Product
                      </button>
                    </div>
                  </div>
                </div>
                {/* Shipping information */}
                <div className="card card-purchase-box my-3">
                  <div className="card-header" id="shippingList">
                    <h5 className="mb-0">
                      <button
                        className="btn btn-purchase-header"
                        data-toggle="collapse"
                        data-target="#collapseShipping-off"
                        aria-expanded="true"
                        aria-controls="collapseShipping"
                      >
                        Shipping address
                      </button>
                    </h5>
                  </div>
                  <div
                    id="collapseShipping"
                    className="collapse show"
                    aria-labelledby="shippingList"
                  >
                    <div className="card-body pt-0">
                      <div className="card purchase-card px-3 py-3 my-2">
                        <div className="form-group" key="ShippingFirstName">
                          <label htmlFor="ShippingFirstName">First name</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="ShippingFirstName"
                            ref={register({ required: true, maxLength: 80 })}
                          />
                          <div className="form-error">
                            {errors.ShippingFirstName &&
                              "First name is required"}
                          </div>
                        </div>
                        <div className="form-group" key="ShippingLastName">
                          <label htmlFor="ShippingLastName">Last name</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="ShippingLastName"
                            ref={register({ required: true, maxLength: 100 })}
                          />
                          <div className="form-error">
                            {errors.ShippingLastName && "Last name is required"}
                          </div>
                        </div>
                        <div className="form-group" key="ShippingEmail">
                          <label htmlFor="ShippingEmail">Email</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="ShippingEmail"
                            ref={register({
                              required: true,
                              pattern: /^\S+@\S+$/i,
                            })}
                          />
                          <div className="form-error">
                            {errors.ShippingEmail?.type === "required" &&
                              "Email is required"}
                          </div>
                          <div className="form-error">
                            {errors.ShippingEmail?.type === "pattern" &&
                              "Please use a valid email"}
                          </div>
                        </div>
                        <div className="form-group" key="ShippingMobileNumber">
                          <label htmlFor="ShippingMobileNumber">
                            Mobile number
                          </label>
                          <input
                            type="tel"
                            className="form-control"
                            placeholder=""
                            name="ShippingMobileNumber"
                            ref={register({
                              required: true,
                              minLength: 6,
                              maxLength: 13,
                            })}
                          />
                          <div className="form-error">
                            {errors.ShippingMobileNumber?.type === "required" &&
                              "Mobile Number is required"}
                          </div>
                          <div className="form-error">
                            {errors.ShippingMobileNumber?.type ===
                              "maxLength" && "Please use a valid mobile number"}
                          </div>
                          <div className="form-error">
                            {errors.ShippingMobileNumber?.type ===
                              "minLength" && "Please use a valid mobile number"}
                          </div>
                        </div>
                        <div className="form-group" key="ShippingAddressLine1">
                          <label htmlFor="ShippingAddressLine1">
                            Address Line 1
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="ShippingAddressLine1"
                            ref={register({ required: true })}
                          />
                          <div className="form-error">
                            {errors.ShippingAddressLine1?.type === "required" &&
                              "Shipping Address is required"}
                          </div>
                        </div>
                        <div className="form-group" key="ShippingAddressLine2">
                          <label htmlFor="ShippingAddressLine2">
                            Address Line 2
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="ShippingAddressLine2"
                            ref={register}
                          />
                        </div>
                        <div className="form-group" key="ShippingCity">
                          <label htmlFor="ShippingCity">City</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="ShippingCity"
                            ref={register({ required: true })}
                          />
                          <div className="form-error">
                            {errors.ShippingCity && "City is required"}
                          </div>
                        </div>
                        <div className="form-group" key="ShippingState">
                          <label htmlFor="ShippingState">State</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="ShippingState"
                            ref={register({ required: true })}
                          />
                          <div className="form-error">
                            {errors.ShippingState && "State is required"}
                          </div>
                        </div>
                        <div className="form-group" key="ShippingZipcode">
                          <label htmlFor="ShippingZipcode">Zipcode</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="ShippingZipcode"
                            ref={register({ required: true })}
                          />
                          <div className="form-error">
                            {errors.ShippingZipcode && "Zipcode is required"}
                          </div>
                        </div>
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            placeholder=""
                            name="BillingAddressCheck"
                            ref={register}
                            data-toggle="collapse"
                            href="#billingList"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="BillingAddressCheck"
                          >
                            Billing address is different from shipping address
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Billing information (Optional) */}
                <div
                  className="card card-purchase-box collapse mb-3"
                  id="billingList"
                >
                  <div className="card-header">
                    <h5 className="mb-0">
                      <button
                        className="btn btn-purchase-header"
                        data-toggle="collapse"
                        data-target="#collapseBilling-off"
                        aria-expanded="true"
                        aria-controls="collapseBilling"
                      >
                        Billing address
                      </button>
                    </h5>
                  </div>
                  <div
                    id="collapseBilling"
                    className="collapse show"
                    aria-labelledby="billingList"
                  >
                    <div className="card-body pt-0">
                      <div className="card purchase-card px-3 py-3 my-2">
                        <div className="form-group" key="BillingFirstName">
                          <label htmlFor="BillingFirstName">First name</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="BillingFirstName"
                            ref={register({ maxLength: 80 })}
                          />
                        </div>
                        <div className="form-group" key="BillingLastName">
                          <label htmlFor="BillingLastName">Last name</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="BillingLastName"
                            ref={register({ maxLength: 100 })}
                          />
                        </div>
                        <div className="form-group" key="BillingEmail">
                          <label htmlFor="BillingEmail">Email</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="BillingEmail"
                            ref={register({ pattern: /^\S+@\S+$/i })}
                          />
                          <div className="form-error">
                            {errors.BillingEmail?.type === "pattern" &&
                              "Please use a valid email"}
                          </div>
                        </div>
                        <div className="form-group" key="BillingMobileNumber">
                          <label htmlFor="BillingMobileNumber">
                            Mobile number
                          </label>
                          <input
                            type="tel"
                            className="form-control"
                            placeholder=""
                            name="BillingMobileNumber"
                            ref={register({ minLength: 6, maxLength: 13 })}
                          />
                          <div className="form-error">
                            {errors.BillingMobileNumber?.type === "maxLength" &&
                              "Please use a valid mobile number"}
                          </div>
                          <div className="form-error">
                            {errors.BillingMobileNumber?.type === "minLength" &&
                              "Please use a valid mobile number"}
                          </div>
                        </div>
                        <div className="form-group" key="BillingAddressLine1">
                          <label htmlFor="BillingAddressLine1">
                            Address Line 1
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="BillingAddressLine1"
                            ref={register}
                          />
                        </div>
                        <div className="form-group" key="BillingAddressLine2">
                          <label htmlFor="BillingAddressLine2">
                            Address Line 2
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="BillingAddressLine2"
                            ref={register}
                          />
                        </div>
                        <div className="form-group" key="BillingCity">
                          <label htmlFor="BillingCity">City</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="BillingCity"
                            ref={register}
                          />
                        </div>
                        <div className="form-group" key="BillingState">
                          <label htmlFor="BillingState">State</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="BillingState"
                            ref={register}
                          />
                        </div>
                        <div className="form-group" key="BillingZipcode">
                          <label htmlFor="BillingZipcode">Zipcode</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="BillingZipcode"
                            ref={register}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <input
                  type="submit"
                  className="btn btn-primary btn-xl col-12"
                  value="Submit Order"
                />
              </form>
            </div>

            {/* Modal on successful submission */}
            <Modal
              show={showSubmitModal}
              onHide={handleClose}
              backdrop="static"
            >
              <Modal.Header closeButton>
                <Modal.Title>Order Successful</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <h4 className="text-center">Your order has been received!</h4>
                <br />
                You will be receiving an email within 2 working days to confirm
                your purchase provide you with a final price. <br />
                <br />
                Please keep a lookout for the mail!
              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={handleClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Purchase;
