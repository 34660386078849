import React, { Component } from "react";

export class FakeArticle extends Component {
  render() {
    return (
      <div id="fake-article">
        <div className="container">
          <div className="row py-3 py-md-3">
            <div className="col-12">
              <div className="section-title">
                <h2 className="mb-4">9 ways to spot fake Lululemon products</h2>
              </div>
              <p className="lead my-4">
                There aren’t many things worse than finding out that your recent
                Lululemon haul was inauthentic. Here we attempt to provide a
                comprehensive guide to some red flags to look out for when
                spotting fake Lululemon.{" "}
                <em>Disclaimer: this list is not exhaustive</em>
              </p>
              <h2 className="alt my-4">1. Hangtag Position</h2>
              <p className="lead my-4">
                The hangtag refers to the cardboard tag. This is commonly
                attached to the left of the piece of clothing. For tops,
                hangtags are attached at the left lower end of the top (near the
                hip). For bottoms, hangtags are located at or slightly below the
                waistband seam (on the left side). For sports bras, hangtags are
                usually located on the left side of the bra as well (slightly
                below the left underarm).
                <br />
                <div class="alert alert-danger my-4" role="alert">
                  <h4 class="alert-heading"> Take note!</h4>
                  Hangtags attached to the riptag (the long slim tag commonly
                  found in the inner collar/waistband of your gear are almost a
                  definite sign of fakes).
                </div>
                {/* <span className="text-danger"></span> */}
              </p>
              <h2 className="alt my-4">2. Hangtag content</h2>
              <p className="lead my-4">
                Here we pay attention to three aspects: (i) font, (ii) typos and
                (iii) QR code.
                <br />
              </p>
              <div className="col-12 col-md-6 mx-auto">
                <img
                  src="img/articles/hangtag-fake-lululemon-1.png"
                  alt="hangtag-fake-lululemon-1"
                  className="my-2 w-100"
                />
                <p className="lead font-italic text-muted py-2 ml-4">
                  <ul>
                    <li>Wrong font</li>
                    <li>
                      Wrong color code (color code is “PKTP”, which is short for
                      “Pink Taupe”)
                    </li>
                    <li>
                      Hangtag position: attached to riptag (HUGE redflag!)
                    </li>
                  </ul>
                </p>
              </div>
              <div className="col-12 col-md-6 mx-auto">
                <img
                  src="img/articles/hangtag-fake-lululemon-2.png"
                  alt="hangtag-fake-lululemon-2"
                  className="w-100"
                />
                <p className="lead font-italic text-muted py-2 ml-4">
                  <ul>
                    <li>Sooooo many typos!!</li>
                    <li>
                      Wrong color code (color code is “BLACK”, but should be
                      “BLK”
                    </li>
                    <li>
                      Hangtag position: attached to riptag (HUGE redflag!)
                    </li>
                    <li>Dodgy “QR code”</li>
                  </ul>
                </p>
              </div>
              <div className="col-12 col-md-6 mx-auto">
                <img
                  src="img/articles/hangtag-fake-lululemon-3.png"
                  alt="hangtag-fake-lululemon-3"
                  className="w-100"
                />
                <p className="lead font-italic text-muted py-2 ml-4">
                  <ul>
                    <li>Point 7 is a huge giveaway</li>
                    <li>Color does not match color code “MSRS” either</li>
                  </ul>
                </p>
              </div>
              <div className="col-12 col-md-6 mx-auto">
                <img
                  src="img/articles/hangtag-fake-lululemon-4.png"
                  alt="hangtag-fake-lululemon-4"
                  className="w-100"
                />
                <p className="lead font-italic text-muted py-2 ml-4">
                  <ul>
                    <li>
                      Typos galore: “Tulu” instead of “Nulu”, “Mycra” instead of
                      “Lycra”, “waistband lies flat against your sin”
                    </li>
                    <li>
                      Wrong color code (color code is “PKTP”, which is short for
                      “Pink Taupe”)
                    </li>
                  </ul>
                </p>
              </div>
              <div className="col-12 col-md-6 mx-auto">
                <img
                  src="img/articles/hangtag-fake-lululemon-5.png"
                  alt="hangtag-fake-lululemon-5"
                  className="w-100"
                />
                <p className="lead font-italic text-muted py-2 ml-4">
                  <ul>
                    <li>Wrong product name, BIG pass.</li>
                    <li>This obviously isn’t a tank</li>
                    <li>Hangtag not positioned at left side of leggings</li>
                  </ul>
                </p>
              </div>
              <div className="col-12 col-md-6 mx-auto">
                <img
                  src="img/articles/hangtag-fake-lululemon-6.png"
                  alt="hangtag-fake-lululemon-6"
                  className="w-100"
                />
                <p className="lead font-italic text-muted py-2 ml-4">
                  <ul>
                    <li>Hangtag attached to riptag</li>
                    <li>No size?</li>
                    <li>Dodgy “luon” tag</li>
                  </ul>
                </p>
              </div>
              <h2 className="alt my-4">3. Rip tag sewn on</h2>
              <p className="lead my-4">
                Rip tags are, as the name suggests, intended to be ripped off
                your gear easily. If you see your rip tag sewn onto the fabric
                of your gear, this is a potential red flag. Forulu, however,
                acknowledges that there were batches of gear that Lululemon
                decided to switch to sewing the rip tags onto the fabric of the
                gear. Those rip tags were hence not “rippable”. We would hence
                like to put a word of caution that this point is not an
                all-or-nothing judgement of product authenticity.
              </p>
              <h2 className="alt my-4">4. Rip tag content</h2>
              <p className="lead my-4">
                Typos such as “lululemon athleticá” or a similar typo
              </p>
              <div className="col-12 col-md-6 mx-auto">
                <img
                  src="img/articles/riptag-fake-lululemon.png"
                  alt="riptag-fake-lululemon"
                  className="w-100"
                />
              </div>
              <div className="col-12 col-md-6 mx-auto">
                <img
                  src="img/articles/riptag-fake-lululemon-2.png"
                  alt="riptag-fake-lululemon-2"
                  className="w-100"
                />
                <p className="lead font-italic text-muted py-2 text-center">
                  Rip tag sewn on, typo at “athleticà”. Dead giveaway.
                </p>
              </div>
              <h2 className="alt my-4">5. Rip tag texture</h2>
              <div className="col-12 col-md-6 mx-auto">
                <img
                  src="img/articles/riptag-texture-fake-lululemon.png"
                  alt="riptag-texture-fake-lululemon"
                  className="w-100"
                />
                <p className="lead font-italic text-muted py-2 text-center">
                  Riptags should have a ribbed texture on both sides, with the
                  front being slightly shimmery. Upon folding, they should be
                  slightly creased.
                </p>
              </div>
              <h2 className="alt my-4">
                6. Size dot (*not applicable to certain tops)
              </h2>
              <p className="lead my-4">
                Size dots are usually located in the inner pockets of your gear.
                For bras, that’s in the left bra pad pocket, for jackets inside
                the left zip pocket. For leggings, you’ll usually find it in the
                inner pocket either on the left front waistband, or at the back
                of the waistband’s inner lining (i.e. Fast and Frees). For
                shorts, size dots may be located either in the left zip pocket
                (such as in the Track That’s), or back zip pocket (for Speed Up
                Short).
              </p>
              <p className="lead my-4">
                We’ve never come across a faded size dot, although size dots do
                vary in “design” depending on the release date of your gear. For
                older gear, size dots may come in just a small white dot with a
                singular size number printed on it, while recent ones have more
                information on them, such as the release date.
              </p>
              <h2 className="alt my-4">7. Stitching</h2>
              <p className="lead my-4">
                Here we want to look at the quality of the seam. The distance
                between the stitches can give an indication as to whether your
                gear may be authentic or not.
              </p>
              <div className="col-12 col-md-6 mx-auto">
                <img
                  src="img/articles/stitching-texture-fake-lululemon.png"
                  alt="stitching-texture-fake-lululemon"
                  className="w-100 "
                />
              </div>
              <h2 className="alt my-4">8. Plastic packaging</h2>
              <p className="lead my-4">
                While we recognize that the plastic packaging that your gear
                comes in may differ slightly from country to country, here is an
                example of an obvious fake to look out for:
              </p>
              <div className="col-12 col-md-6 mx-auto">
                <img
                  src="img/articles/plastic-packaging-fake-lululemon.png"
                  alt="plastic-packaging-fake-lululemon"
                  className="w-100"
                />
              </div>
              <h2 className="alt my-4">9. Price</h2>
              <p className="lead my-4">
                Managed to find a listing for brand new Lululemon gear at
                dirt-cheap prices? Sounds dodgy. Proceed with caution! Better
                yet, don’t move ahead with the deal at all.
              </p>
              <p className="lead my-4">
                Still unsure if your gear is real or fake after reading this
                page? We’d recommend playing it safe if you still have your
                doubts on the authenticity of your gear.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FakeArticle;
