import React, { Component } from "react";

export class testimonials extends Component {
  render() {
    return (
      <div id="testimonials">
        <div className="container">
          <div className="row pt-3 pt-md-5">
            <div className="col-12 col-md-5 mr-md-5 section-title">
              <h2 className="mb-0 mb-md-2">
                Kind words<br></br>from fans
              </h2>
            </div>
            <div className="col-12 py-3 col-md-4 offset-md-1 pl-md-0 mt-md-5">
              <div className="card">
                <img
                  className="card-img-top country-img mt-4 ml-4"
                  src={this.props.data ? this.props.data.img_1 : "Loading"}
                  alt="usa_flag"
                />
                <div className="card-body">
                  <p className="card-text">
                    {this.props.data ? this.props.data.text_1 : "Loading"}
                  </p>
                  <p className="subtitle-1">
                    {this.props.data ? this.props.data.name_1 : "Loading"}
                  </p>
                  <p className="lead-2 testimonial-handle">
                    {this.props.data ? this.props.data.handle_1 : "Loading"}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row pb-5 justify-content-center">
            <div className="col-12 col-md-4 offset-md-1 my-md-n10">
              <div className="card">
                <img
                  className="card-img-top country-img mt-4 ml-4"
                  src={this.props.data ? this.props.data.img_2 : "Loading"}
                  alt="usa_flag"
                />
                <div className="card-body">
                  <p className="card-text">
                    {this.props.data ? this.props.data.text_2 : "Loading"}
                  </p>
                  <p className="subtitle-1">
                    {this.props.data ? this.props.data.name_2 : "Loading"}
                  </p>
                  <p className="lead-2 testimonial-handle">
                    {this.props.data ? this.props.data.handle_2 : "Loading"}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4 pl-md-0 py-3 py-md-0">
              <div className="card">
                <img
                  className="card-img-top country-img mt-4 ml-4"
                  src={this.props.data ? this.props.data.img_3 : "Loading"}
                  alt="usa_flag"
                />
                <div className="card-body">
                  <p className="card-text">
                    {this.props.data ? this.props.data.text_3 : "Loading"}
                  </p>
                  <p className="subtitle-1">
                    {this.props.data ? this.props.data.name_3 : "Loading"}
                  </p>
                  <p className="lead-2 testimonial-handle">
                    {this.props.data ? this.props.data.handle_3 : "Loading"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      // </div >
    );
  }
}

export default testimonials;
